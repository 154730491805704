<template>
  <PreviewText
    ref="textRef"
    v-bind="$props"
    :trigger-class="triggerClass"
    :regex="/^-?(\d+)?\.?(\d+)?/"
    :is-number="true"
    @update:open="$emit('update:open', $event)"
  />
</template>

<script lang="ts" setup>
import type { TaskFieldRef } from '#field/types'

defineOptions({
  inheritAttrs: false,
})

defineProps({
  taskId: {
    type: String,
    required: true,
  },
  value: {
    type: String,
    required: false,
  },
  preview: {
    type: Boolean,
    default: false,
  },
  fieldId: {
    type: String,
    required: true,
  },
  contentClass: {
    type: String,
    required: false,
  },
  textPreviewClass: {
    type: String,
    required: false,
  },
  textWrapperClass: {
    type: String,
    required: false,
  },
  textEditClass: {
    type: String,
    required: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  triggerClass: {
    type: Function as PropType<(active: boolean) => string[]>,
    required: false,
  },
  alertMessage: {
    type: String,
    required: false,
  },
  measurement: {
    type: String,
    defalut: '',
  },
})

defineEmits(['update:open'])

const textRef = ref<TaskFieldRef | null>(null)

defineExpose({
  open: () => {
    textRef.value?.open()
  },
  close: () => {
    textRef.value?.close()
  },
  delete: () => {
    textRef.value?.delete()
  },
})
</script>
